import React, { useState } from "react";
import {
  Button as AntdButton,
  Drawer,
  message,
  Col,
  Divider,
  Row,
  Typography,
  Collapse,
  Modal,
  Result,
  Badge,
} from "antd";
import { Form, Input, Button, Space, Select } from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  PhoneOutlined,
  OrderedListOutlined,
  ArrowRightOutlined,
  PlusSquareFilled,
  MinusSquareFilled,
} from "@ant-design/icons";
import { fetchApi } from "../../Api";
import { Link } from "react-router-dom";

const { Option } = Select;
const { Panel } = Collapse;
const Text = Typography.Text;

const ENDPOINT = "https://node-azfun-test.azurewebsites.net/api/handleOrder";
const ENDPOINT_LOCAL = "http://localhost:7071/api/handleOrder";

interface IOrder {
  customer: {
    name?: string;
    email?: string;
    phone?: string;
    adress?: string;
    zip?: string;
    message?: string;
    led?: Array<{
      led: string;
      antal: string;
      navneplade_linje1: string;
      navneplade_linje2: string;
      navneplade_linje3: string;
    }>;
  };
}

export const storeOrderInfo = (order: IOrder): Promise<IOrder> => {
  const body = JSON.stringify({ order: order });
  return new Promise((resolve, reject) => {
    fetchApi<IOrder>(ENDPOINT, "POST", body)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const Bestil: React.FC = () => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);
  //TODO FIX PRICE
  const [price, setPrice] = useState<number>(0);
  const ledStr: string[] = [
    "14 / 36 cm",
    "15 / 39 cm",
    "16 / 41 cm",
    "17 / 44 cm",
    "18 / 46 cm",
    "19 / 49 cm",
    "20 / 52 cm",
    "21 / 55 cm",
    "22 / 58 cm",
    "23 / 60 cm",
    "24 / 63 cm",
    "25 / 65 cm",
    "26 / 68 cm",
    "27 / 70 cm",
    "28 / 73 cm",
    "29 / 75 cm",
  ];

  const [form] = Form.useForm();

  const onFinish = (values: IOrder) => {
    setProcessing(true);
    console.log(values);
    storeOrderInfo(values)
      .then((resp) => {
        setProcessing(false);
        message.success(
          "Tak for din ordre. Den vil blive behandlet hurtigst muligt."
        );
        setShowResult(true);
      })
      .catch(() => {
        setProcessing(false);
        message.error("Der skete en fejl. Prøv venligst igen.");
      });
  };

  const calculateTotal = (values: IOrder) => {
    console.log(typeof values);
  };

  return (
    <>
      <Row style={{ marginTop: "50px" }}>
        <Col xs={1} md={1} lg={2} xl={4} />
        <Col style={{ textAlign: "left" }} xs={22} md={22} lg={9} xl={7}>
          <Badge.Ribbon text={"Priser"} placement={"start"} color={"#ff0000"}>
            <div
              style={{
                boxShadow: "0px 1px 6px rgba(0,0,0,0.2)",
                marginTop: 70,
                borderRadius: 4,
                padding: 25,
              }}
            >
              <h1 style={{ fontSize: "32px", fontWeight: "bold" }}>
                Alle størrelser <b style={{ color: "red" }}>135 kr</b>.{" "}
              </h1>
              <h3 style={{ fontWeight: "bold" }}> Med navneplade </h3>
              <h4> (hundetegn) og gravering +60kr </h4>
              <h3 style={{ fontWeight: "bold", marginTop: "30px" }}>
                {" "}
                Fragtpriser{" "}
              </h3>
              <p>
                <h4>
                  {" "}
                  0-1kg / +39kr <br /> 1-5kg / +52kr <br /> 5-10kg / +62kr{" "}
                </h4>
              </p>
            </div>
          </Badge.Ribbon>

          <Badge.Ribbon
            text={"Find den rigtige størrelse"}
            placement={"start"}
            color={"#ff0000"}
          >
            <div
              style={{
                boxShadow: "0px 1px 6px rgba(0,0,0,0.2)",
                marginTop: 50,
                borderRadius: 4,
                padding: 25,
                marginBottom: 50,
              }}
            >
              <p>
                OBS - Find den rigtige størrelse: <br /> Mål hunden om halsen,
                og læg 6 cm til.
              </p>
              <div style={{ textAlign: "left" }}>
                <p>
                  {" "}
                  Dankæden leveres ligeledes i metermål, samt til mange special
                  opgaver.{" "}
                </p>
                <p>
                  {" "}
                  F.eks: <br /> - Boxkæder til heste leveres med 2 solide
                  messing karabinhager <br />- Afbindingskæder til hunde i alle
                  længder med messinghager <br />
                  - Kobbelkæder til to eller flere hunde <br />
                </p>
                <p style={{ fontStyle: "italic" }}>
                  {" "}
                  Har De special ønsker så ring blot til os på telefon:{" "}
                  <a style={{ color: "red" }} href="tel:40 51 80 33">
                    {" "}
                    +45 40 51 80 33 <PhoneOutlined />
                  </a>{" "}
                </p>
              </div>
            </div>
          </Badge.Ribbon>

          <Collapse
            expandIconPosition={"right"}
            expandIcon={({ isActive }) =>
              !isActive ? <PlusSquareFilled /> : <MinusSquareFilled />
            }
          >
            <Panel header={"Evighedsgaranti"} key="1">
              <Text>
                Dankæden giver garanti for, at kæden holder hele hundens
                levetid.
              </Text>
            </Panel>
            <Panel header={"Byttegaranti"} key="2">
              <Text>
                Som kunde hos dankæden tilbyder vi byttegaranti i takt med at
                hunden vokser. Det vil sige at du altid kan få byttet din gamle
                kæde til en ny, i tilfælde af, at hunden vokser ud af den gamle.
              </Text>
            </Panel>
            <Panel header={"Trækgaranti"} key="3">
              <Text>Dankæden er træktestet til over 700kg.</Text>
            </Panel>
            <Panel header={"Fantastisk kvalitet"} key="4">
              <Text>
                Den trestjernede dankæde er produceret i syrefast rustfrit stål
                af bedste kvalitet. Alle led er 100% ens, da de er produceret på
                automatbænk.
              </Text>
            </Panel>
          </Collapse>
        </Col>
        <Col xs={1} md={1} lg={1} xl={1}></Col>
        <Col xs={1} md={1} lg={1} xl={1} />
        <Col style={{ marginTop: "70px" }} xs={22} md={22} lg={10} xl={8}>
          <Badge.Ribbon
            placement="start"
            color={"#ff0000"}
            text={
              <>
                <OrderedListOutlined /> Min Ordre
              </>
            }
          >
            <div
              style={{
                boxShadow: "0 2px 6px rgba(0,0,0, 0.2)",
                borderRadius: "2px",
                padding: "25px",
              }}
            >
              <div
                style={{
                  textAlign: "right",
                  paddingBottom: "50px",
                  color: "red",
                }}
              >
                {/*
              <Button type={"default"} color={"red"}>
                {" "}
                <QuestionCircleOutlined />{" "}
              </Button>
              */}
              </div>
              <div style={{ paddingBottom: "0px" }}>
                <Text>Dine oplysninger</Text>
              </div>
              <Divider />
              <Form
                form={form}
                name="dynamic_form_nest_item"
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  name={"name"}
                  rules={[{ required: true, message: "Indtast navn" }]}
                >
                  <Input placeholder="Navn" />
                </Form.Item>
                <Form.Item
                  name={"email"}
                  rules={[
                    { required: true, type: "email", message: "Indtast mail" },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  name={"phone"}
                  rules={[
                    {
                      required: true,
                      type: "string",
                      message: "Indtast telefonnummer",
                    },
                  ]}
                >
                  <Input placeholder="Telefonnummer" />
                </Form.Item>
                <Form.Item
                  name={"adress"}
                  rules={[
                    {
                      required: true,
                      type: "string",
                      message: "Indtast adresse",
                    },
                  ]}
                >
                  <Input placeholder="Adresse" />
                </Form.Item>
                <Form.Item
                  name={"zip"}
                  rules={[
                    {
                      required: true,
                      type: "string",
                      message: "Indtast postnummer og by",
                    },
                  ]}
                >
                  <Input placeholder="Postnummer og by" />
                </Form.Item>
                <Form.Item name={"message"}>
                  <Input.TextArea placeholder="Besked" />
                </Form.Item>

                <div style={{ paddingBottom: "0px" }}>
                  <Text>Bestilling med og uden navneplade</Text>
                </div>
                <Divider />
                <div style={{ textAlign: "left" }}>
                  <Form.List name={"led"}>
                    {(fields = [], { add, remove }) => (
                      <>
                        {fields.map((field) => (
                          <>
                            <Space key={field.key} align="baseline">
                              <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, curValues) =>
                                  prevValues.led !== curValues.led
                                }
                              >
                                {() => (
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "led"]}
                                    fieldKey={[field.fieldKey, "led"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Indtast led størrelse!",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder={"Vælg størrelse"}
                                      style={{ width: 200 }}
                                    >
                                      {ledStr.map((item: string) => (
                                        <Option key={item} value={item}>
                                          {item}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                )}
                              </Form.Item>
                            </Space>
                            <div>
                              <Space key={field.key} align="center">
                                <Form.Item
                                  {...field}
                                  name={[field.name, "antal"]}
                                  fieldKey={[field.fieldKey, "antal"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Indtast antal!",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder={"Antal"}
                                    style={{ width: 200 }}
                                  />
                                </Form.Item>
                              </Space>
                            </div>

                            <h4> Navneplade (max 12 tegn pr. linje) </h4>
                            <div>
                              <Space key={field.key} align="end">
                                <Form.Item
                                  {...field}
                                  name={[field.name, "navneplade_linje1"]}
                                  fieldKey={[field.fieldKey, "navneplade1"]}
                                  rules={[
                                    {
                                      pattern: new RegExp(
                                        "^[a-zA-ZæÆøØåÅ0-9+]{0,12}$"
                                      ),
                                      message: "Max 12 tegn tilladt!",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Linje 1"
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "navneplade_linje2"]}
                                  fieldKey={[field.fieldKey, "navneplade2"]}
                                  rules={[
                                    {
                                      pattern: new RegExp(
                                        "^[a-zA-ZæÆøØåÅ0-9+]{0,12}$"
                                      ),
                                      message: "Max 12 tegn tilladt!",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Linje 2"
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "navneplade_linje3"]}
                                  fieldKey={[field.fieldKey, "navneplade3"]}
                                  rules={[
                                    {
                                      pattern: new RegExp(
                                        "^[a-zA-ZæÆøØåÅ0-9+]{0,12}$"
                                      ),
                                      message: "Max 12 tegn tilladt!",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Linje 3"
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Space>
                            </div>

                            <div style={{ marginTop: 20, textAlign: "end" }}>
                              <Space key={field.key} align="center">
                                <Button
                                  style={{ width: 100 }}
                                  onClick={() => remove(field.name)}
                                  type="primary"
                                >
                                  Fjern <MinusCircleOutlined />
                                </Button>
                              </Space>
                            </div>
                            <Divider />
                          </>
                        ))}

                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Tilføj ny størrelse
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </div>
                <Form.Item>
                  <div style={{ textAlign: "center" }}>
                    <div>
                      {/* <Typography.Text>Total: {price} DKK</Typography.Text> */}
                    </div>
                    <Divider />
                    <Button
                      loading={processing}
                      type="primary"
                      style={{
                        fontSize: "16px",
                        height: "35px",
                        marginTop: "25px",
                        width: 300,
                        backgroundColor: "#ff0000",
                      }}
                      htmlType="submit"
                    >
                      Bestil <ArrowRightOutlined />
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Badge.Ribbon>
        </Col>
        <Col xs={1} md={1} lg={2} xl={4}></Col>
      </Row>

      <Modal
        visible={showResult}
        closable={false}
        maskClosable={true}
        footer={null}
      >
        <Result
          status="success"
          title="Tak!"
          subTitle={
            <>
              {" "}
              <Divider />
              Din bestilling blev modtaget. Du vil modtage en faktura på dit køb
              snarest.
              <Divider />{" "}
            </>
          }
          extra={[
            <Link to={"/"}>
              <Button style={{ width: 100 }} type="primary" key="home">
                OK
              </Button>
            </Link>,
            /* <Button  type='primary' onClick={ () => setShowResult(false)} key="close">Luk</Button> */
          ]}
        />
      </Modal>
    </>
  );
};
