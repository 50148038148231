import React from "react";
import { Row, Col, Typography } from "antd";
import { MailOutlined, PhoneOutlined, RightOutlined } from "@ant-design/icons";
import { useWindowDimensions } from "../../../Hooks";

import "./styles.scss";
const Title = Typography.Title;
const Text = Typography.Text;

export const Footer: React.FC = () => {
  const { height, width } = useWindowDimensions();
  return (
    <div className="footer">
      <Row
        style={{
          boxShadow: "0px 0px px 0px #9e9e9e",
          paddingTop: "4%",
          paddingBottom: "2%",
          marginLeft: "0%",
          paddingRight: "5%",
          fontFamily: "Poppins",
        }}
      >
        <Col
          style={
            width < 992
              ? { textAlign: "center", color: "red", lineHeight: "25px" }
              : { color: "#fff", lineHeight: "25px" }
          }
          xs={0}
          sm={0}
          md={0}
          lg={3}
          xl={3}
        ></Col>

        <Col
          style={
            width < 992
              ? { textAlign: "center", color: "#fff", lineHeight: "25px" }
              : { color: "#fff", lineHeight: "25px" }
          }
          xs={24}
          sm={24}
          md={24}
          lg={4}
          xl={4}
        >
          <Title style={{ color: "#fff", fontSize: "18px" }}>Firma</Title>
          <Text style={{ fontSize: "14px", color: "#fff" }}>PN Entreprise</Text>
          <br></br>
          <Text style={{ fontSize: "14px", color: "#fff" }}>CVR: 12971737</Text>
        </Col>

        <Col
          style={
            width < 992
              ? {
                  textAlign: "center",
                  marginTop: 20,
                  color: "#fff",
                  lineHeight: "25px",
                }
              : { lineHeight: "25px" }
          }
          xs={24}
          sm={24}
          md={24}
          lg={4}
          xl={4}
        >
          <Title style={{ color: "#fff", fontSize: "18px" }}>Adresse</Title>
          <Text style={{ fontSize: "14px", color: "#fff" }}>
            Vandkarsevej 46
          </Text>
          <br></br>
          <Text style={{ fontSize: "14px", color: "#fff" }}>2880 Bagsværd</Text>
        </Col>

        <Col
          style={
            width < 992
              ? {
                  textAlign: "center",
                  marginTop: 20,
                  color: "#fff",
                  lineHeight: "25px",
                }
              : { lineHeight: "25px" }
          }
          xs={24}
          sm={24}
          md={24}
          lg={4}
          xl={4}
        >
          <Title style={{ color: "#fff", fontSize: "18px" }}>Kontakt</Title>
          <Text style={{ fontSize: "14px", color: "#fff" }}>
            ​​​
            <PhoneOutlined />{" "}
            <a
              style={{ fontStyle: "italic", color: "#fff" }}
              href="tel:40518033"
            >
              +45 40 51 80 33
            </a>
          </Text>
          <br></br>
          <Text style={{ fontSize: "14px", color: "#fff" }}>
            <MailOutlined />{" "}
            <a
              style={{ fontStyle: "italic", color: "#fff" }}
              href="mailto:pnentreprise@outlook.dk "
            >
              pnentreprise@outlook.dk
            </a>
          </Text>
        </Col>

        <Col
          style={
            width < 992
              ? {
                  textAlign: "center",
                  marginTop: 20,
                  color: "#fff",
                  lineHeight: "25px",
                }
              : { lineHeight: "25px" }
          }
          xs={24}
          sm={24}
          md={24}
          lg={6}
          xl={6}
        >
          <Title style={{ color: "#fff", fontSize: "18px" }}>Betaling</Title>
          <Text style={{ fontSize: "14px", color: "#fff" }}>
            ​​​
            <RightOutlined /> Bankoverførsel: <br /> Nordea Bank:
            2256-0749624027 <br />
            IBAN: DK50 2000 0749 6240 27 <br />
            <b>Fakturanummer bedes anføres ved bankoverførsel.</b>
          </Text>
          <br></br>
          <Text style={{ fontSize: "14px", color: "#fff" }}>
            <RightOutlined /> Mobilepay:
            <br /> 31268040 - K. Nielsen
          </Text>
        </Col>

        <Col
          style={{ color: "#fff", lineHeight: "25px" }}
          xs={0}
          sm={0}
          md={0}
          lg={0}
          xl={0}
        ></Col>
      </Row>
    </div>
  );
};
export default Footer;
