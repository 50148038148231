import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Button as AntdButton,
  Divider as AntdDivider,
  Row,
  Col,
  Image,
  Collapse,
  Badge,
  Button,
} from "antd";
import {
  ArrowRightOutlined,
  PlusSquareFilled,
  MinusSquareFilled,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import "./styles.scss";

import picture1 from "../../../../res/img/produktion.jpg";
import produkt2 from "../../../../res/img/produkt2.jpg";
import produkt3 from "../../../../res/img/produkt3.jpg";
import workshop from "../../../../res/img/værksted2.jpg";
import ringe from "../../../../res/img/ringe.jpg";
import ringe2 from "../../../../res/img/ringe2.jpg";
import schaf1 from "../../../../res/img/schaf_badge_1.png";
import schaf2 from "../../../../res/img/schaf_badge_2.png";
import hund_elton from "../../../../res/img/hund_elton.jpeg";
import hund_i_sne from "../../../../res/img/hund_i_sne_polis.jpg";
import hund_polis from "../../../../res/img/hund_polis.jpeg";
import kæde_produktion_kasse from "../../../../res/img/kæde_produktion_kasse.jpg";
import kæde_produktion_kasse2 from "../../../../res/img/kæde_produktion_kasse2.jpg";
import kæde_produktion_pose from "../../../../res/img/kæde_produktion_pose.jpg";
import kæde_produktion_pose2 from "../../../../res/img/kæde_produktion_pose2.jpg";
import mand_hund_polis from "../../../../res/img/mand_hund_polis.jpeg";
import mand_hund_polis2 from "../../../../res/img/mand_hund_polis2.jpeg";
import mand_hund_polis3 from "../../../../res/img/mand_hund_polis3.jpeg";
import navneplade from "../../../../res/img/navneplade.jpg";
import navneplade2 from "../../../../res/img/navneplade2.jpg";
import navneplade3 from "../../../../res/img/navneplade3.jpg";
import navneplade4 from "../../../../res/img/navneplade4.jpg";
import navneplade5 from "../../../../res/img/navneplade5.jpg";
import logo from "../../../../res/img/home_logo.png";
import stjerne from "../../../../res/img/stjernerød.png";

import { Link } from "react-router-dom";

const galleriSrces = [
  produkt2,
  produkt3,
  workshop,
  ringe,
  ringe2,
  hund_elton,
  hund_polis,
  hund_i_sne,
  kæde_produktion_kasse,
  kæde_produktion_kasse2,
  kæde_produktion_pose,
  kæde_produktion_pose2,
  mand_hund_polis,
  mand_hund_polis2,
  mand_hund_polis3,
  navneplade,
  navneplade2,
  navneplade3,
  navneplade4,
  navneplade5,
];

const { Panel } = Collapse;

const Text = Typography.Text;

interface IAbout {
  headerText?: string;
}

export const About: React.FC<IAbout> = (props) => {
  const refEle = useRef(null);
  const [getWindowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  });

  return (
    <div ref={refEle} className="pitchWrapper">
      <Row
        style={{
          marginTop: "-125px",
          paddingBottom: "100px",
          fontSize: "18px",
        }}
      >
        <Col lg={6}></Col>
        <Col
          style={{
            borderRadius: "2px",
            padding: 30,
          }}
          lg={12}
        >
          <Text style={{ fontSize: "16px", lineHeight: "25px" }}>
            <div
              style={{
                textAlign: "left",
                display: "flex",
                marginBottom: 30,
                backgroundColor: "#ff0000",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                boxShadow: "0 2px 6px rgba(0,0,0, 0.2)",
                borderRadius: 4,
              }}
            >
              <div
                style={{
                  marginLeft: 10,
                  alignSelf: "center",
                  padding: 20,
                  color: "#fff",
                  display: "flex",
                  borderRadius: 4,
                }}
              >
                <div>
                  <div style={{ fontWeight: 600 }}>
                    Dankæden fejrer 50 år: 1972-2022
                  </div>
                  <div style={{ fontSize: 14, marginTop: 5 }}>
                    Dankæden har siden 1972 været hundens trofaste ven. Gør din
                    hund en tjeneste, og bestil din dankæde i dag! <br />
                  </div>
                </div>
              </div>
            </div>
            <Badge.Ribbon
              placement="start"
              text="Om Dankæden"
              color={"#ff0000"}
            >
              <div
                style={{
                  marginTop: 20,
                  boxShadow: "0 2px 6px rgba(0,0,0, 0.2)",
                  padding: 20,
                  fontSize: 14,
                  borderRadius: 4,
                }}
              >
                Den trestjernede dankæde så dagens lys i 1972. Årene op til var
                brugt til fremstilling af mange forskellige størrelser i bredde
                og længde - Vi endte op med, at de 32mm. ledstørrelse var den
                helt rigtige. <br />
                <br />
                Dankæden er blevet testet hos politiets hundetjeneste i mange
                schæferhundeklubber i Danmark, Tyskland, Sverige og Norge, og er
                sågar også til at finde i USA. <br /> <br />
                Produktet er af bedste kvalitet, og fremstillet i syrefast
                rustfrit stål. <br></br>Alle led er 100% ens, da de er
                fremstillet på automatbænk. <br />
              </div>
            </Badge.Ribbon>
            <div
              style={{ textAlign: "center", margin: "50px", display: "flex" }}
            >
              <div style={{ flex: 1 }}>
                <img width={"85%"} src={schaf1}></img>
              </div>
              <div style={{ flex: 1 }}>
                <img width={"85%"} src={schaf2}></img>
              </div>
            </div>
          </Text>
          <div
            style={{
              borderBottomLeftRadius: "12px",
              borderTopLeftRadius: "4px",
              borderBottomRightRadius: "4px",
              borderTopRightRadius: "12px",
              paddingTop: "1%",
              paddingBottom: "1%",
              fontSize: "21px",
              fontFamily: "Poppins",
            }}
          >
            <br />
            <Collapse
              expandIconPosition={"right"}
              expandIcon={({ isActive }) =>
                !isActive ? <PlusSquareFilled /> : <MinusSquareFilled />
              }
            >
              <Panel header={"Evighedsgaranti"} key="1">
                <Text>
                  Dankæden giver garanti for, at kæden holder hele hundens
                  levetid.
                </Text>
              </Panel>
              <Panel header={"Byttegaranti"} key="2">
                <Text>
                  Som kunde hos dankæden tilbyder vi byttegaranti i takt med at
                  hunden vokser. Det vil sige at du altid kan få byttet din
                  gamle kæde til en ny, i tilfælde af, at hunden vokser ud af
                  den gamle.
                </Text>
              </Panel>
              <Panel header={"Trækgaranti"} key="3">
                <Text>Dankæden er træktestet til over 700kg.</Text>
              </Panel>
              <Panel header={"Fantastisk kvalitet"} key="4">
                <Text>
                  Den trestjernede dankæde er produceret i syrefast rustfrit
                  stål af bedste kvalitet. Alle led er 100% ens, da de er
                  produceret på automatbænk.
                </Text>
              </Panel>
            </Collapse>
            <br></br>
          </div>
          <div style={{ textAlign: "center" }}>
            <AntdButton
              type="primary"
              style={{
                fontSize: "16px",
                height: "40px",
                marginTop: "25px",
                width: 300,
                backgroundColor: "#ff0000",
              }}
            >
              <Link to="/bestil">
                Bestil Dankæden <ArrowRightOutlined />
              </Link>
            </AntdButton>
          </div>
        </Col>

        <Col lg={6}></Col>
      </Row>
      <Row justify={"center"} style={{ width: "100%", marginTop: "0px" }}>
        <div
          style={{
            paddingLeft: 0.05 * getWindowWidth,
            paddingRight: 0.05 * getWindowWidth,
            lineHeight: 0,
            textAlign: "center",
          }}
        >
          <Image
            style={{ margin: 0, padding: 0 }}
            src={picture1}
            width={120}
            height={120}
          />
          {galleriSrces.map((src, i) => (
            <Image key={`picture_${i}`} width={120} height={120} src={src} />
          ))}
        </div>
      </Row>
    </div>
  );
};
