import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import "antd/dist/antd.css";
import "./styles.scss";
import "./App.css";
import { Home, Bestil, Forhandler, Kontakt } from './Pages'
import { Header, Footer, Credits} from "./Components";
import { Drawer } from "antd";


function App() {
  return (
    <Router>
      <Switch>
      <Route path='/Kontakt'>
        <Header current={'kontakt'} />
          <Kontakt />
        </Route>
       <Route path='/forhandler'>
        <Header current={'forhandler'} />
          <Forhandler />
        </Route>
        <Route path='/bestil'>
          <Header current={'bestil'} />
          <Bestil />
        </Route>
        <Route path='/'>
          <Home />
        </Route>
      </Switch>
      {
      <Footer/>}
      <div style={{marginTop: '200px'}}>
      {/* <Credits/> */ }
      </div>
    </Router>
  );
}

export default App;
