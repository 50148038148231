import React, { useState } from "react";
import {
  Button as AntdButton,
  Statistic,
  Typography,
  Button,
  Col,
  Form,
  Input,
  Row,
  message,
  Result,
  Divider,
} from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import "../../styles.scss";
import redStar from "../../res/img/stjernerød.png";
import { fetchApi } from "../../Api";
import Modal from "antd/lib/modal/Modal";
import { Link } from "react-router-dom";
const Text = Typography.Text;

const ENDPOINT = "https://node-azfun-test.azurewebsites.net/api/handleDealer";
const ENDPOINT_LOCAL = "http://localhost:7071/api/handleDealer";

interface IDealer {
  name?: string;
  email?: string;
  phone?: string;
  shop?: string;
  other?: string;
}

export const dealerRequest = (dealer: IDealer): Promise<IDealer> => {
  const body = JSON.stringify({ dealer: dealer });
  return new Promise((resolve, reject) => {
    fetchApi<IDealer>(ENDPOINT, "POST", body)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const Forhandler: React.FC = () => {
  const [processing, setProcessing] = useState(false);
  const [showResult, setShowResult] = useState(false);

  const onFinish = (values: IDealer) => {
    setProcessing(true);
    dealerRequest(values)
      .then((resp) => {
        message.success(
          "Tak for din henvendelse. Vi vender tilbage hurtigst muligt."
        );
      })
      .catch(() => {
        setProcessing(false);
        message.error("Der skete en fejl. Prøv venligst igen.");
      })
      .finally(() => {
        setProcessing(false);
        setShowResult(true);
      });
  };

  return (
    <Row style={{ marginTop: "100px", fontFamily: "Poppins" }}>
      <Col xs={0} sm={4} lg={6}></Col>
      <Col xs={24} sm={16} lg={12}>
        <div
          style={{
            boxShadow: "0 2px 6px rgba(0,0,0, 0.2)",
            borderRadius: "8px",
            padding: "25px",
          }}
        >
          <div style={{ marginBottom: "50px", textAlign: "center" }}>
            <Text
              style={{ fontSize: "14px", opacity: "0.7", fontStyle: "italic" }}
            >
              Udfyld formularen, og bliv forhandler af{" "}
              <a style={{ color: "red" }}>Den Trestjernede Dankæde</a> <br /> Du
              vil blive kontaktet indenfor 2 hverdage.
            </Text>
          </div>
          <div style={{ marginBottom: "50px", textAlign: "center" }}>
            <img src={redStar}></img> <img src={redStar}></img>{" "}
            <img src={redStar}></img> <br />
          </div>
          <div style={{ borderRadius: "2px" }}>
            <Form onFinish={onFinish} name="nest-messages">
              <Form.Item
                name={"name"}
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Skriv venligst dit navn",
                  },
                ]}
              >
                <Input placeholder="Navn" />
              </Form.Item>
              <Form.Item
                name={"email"}
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Skriv venligst din email",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                name={"phone"}
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Skriv venligst dit telefonnummer",
                  },
                ]}
              >
                <Input placeholder="Telefonnummer" />
              </Form.Item>
              <Form.Item name={"shop"} rules={[{ type: "string" }]}>
                <Input placeholder="Evt. Butiksadresse" />
              </Form.Item>
              <Form.Item name={"other"} rules={[{ type: "string" }]}>
                <Input.TextArea placeholder="Evt. Opdrætter/Kennel/Andet/Bemærkninger" />
              </Form.Item>
              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  style={{
                    marginTop: 10,
                    width: 300,
                    backgroundColor: "#ff0000",
                  }}
                  loading={processing}
                  type="primary"
                  htmlType="submit"
                >
                  Bliv forhandler <ArrowRightOutlined />
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Col>
      <Col xs={0} sm={4} lg={6}></Col>

      <Modal
        visible={showResult}
        closable={false}
        maskClosable={true}
        footer={null}
      >
        <Result
          status="success"
          title="Tak!"
          subTitle={
            <>
              {" "}
              <Divider />
              Din ansøgning om at blive forhandler blev modtaget. Du vil blive
              kontaktet hurtigst muligt.
              <Divider />{" "}
            </>
          }
          extra={[
            <Link to={"/"}>
              <Button style={{ width: 100 }} type="primary" key="home">
                OK
              </Button>
            </Link>,
            /* <Button  type='primary' onClick={ () => setShowResult(false)} key="close">Luk</Button> */
          ]}
        />
      </Modal>
    </Row>
  );
};

export default Forhandler;
