import React, { useState } from "react";
import "./styles.scss";
import {
  Menu as AntdMenu,
  Affix as AntdAffix,
  Row,
  Col,
  Divider,
  Image,
} from "antd";
import {
  HomeFilled,
  ProfileFilled,
  UsergroupAddOutlined,
  PhoneFilled,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import logo from "../../../res/img/home_logo.png";

interface IHeaderProps {
  current: string;
}

export const Header: React.FC<IHeaderProps> = (props) => {
  const [getWindowWidth, setWindowWidth] = useState(window.innerWidth);

  return (
    <div className="header">
      <Row style={{ width: "100%" }}>
        <Col
          span={2}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {getWindowWidth > 1000 && (
            <div
              style={{
                marginTop: 0,
                marginLeft: 100,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Image
                style={{
                  borderRadius: "2px",
                  alignSelf: "left",
                  backgroundColor: "#fff",
                  marginLeft: 0,
                  overflow: "hidden",
                  boxShadow: "0 2px 6px rgba(0,0,0, 0.2)",
                }}
                preview={false}
                width={75}
                src={logo}
              />
            </div>
          )}
        </Col>
        <Col
          span={20}
          style={{
            display: "flex",
            justifyContent: "center",
            height: 40,
            marginBottom: 40,
          }}
        >
          <div
            style={{
              textAlign: "center",
              boxShadow: "0 1px 6px rgba(0,0,0,0.1)",
              borderBottomLeftRadius: 12,
              borderBottomRightRadius: 12,
            }}
          >
            <AntdMenu
              selectedKeys={[props.current]}
              mode="horizontal"
              style={{
                fontSize: "14px",
                fontFamily: "Poppins",
                backgroundColor: "#ff0000",
              }}
            >
              <AntdMenu.Item
                className={"menu-item"}
                key="home"
                icon={<HomeFilled />}
              >
                <Link to="/">Hjem</Link>
              </AntdMenu.Item>
              <Divider type={"vertical"} />
              <AntdMenu.Item key="bestil" icon={<ProfileFilled />}>
                <Link to="/bestil">Bestil</Link>
              </AntdMenu.Item>
              <Divider type={"vertical"} />
              <AntdMenu.Item key="forhandler" icon={<UsergroupAddOutlined />}>
                <Link to="/forhandler">Bliv forhandler</Link>
              </AntdMenu.Item>
              <Divider type={"vertical"} />
              <AntdMenu.Item key="kontakt" icon={<PhoneFilled />}>
                <Link to="/kontakt">Kontakt </Link>
              </AntdMenu.Item>
            </AntdMenu>
          </div>
        </Col>
        <Col
          span={2}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        ></Col>
      </Row>
    </div>
  );
};
export default Header;
