export const fetchApi = async <T>(
  serviceMethod: string,
  method: "POST" | "GET" | "DELETE" = "GET",
  body?: BodyInit
): Promise<T> => {
  return new Promise((resolve, reject) => {
    if (serviceMethod) {
      const endpoint = serviceMethod;
      const settings: RequestInit = {
        method: method,
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: body,
      };
      try {
        fetch(endpoint, settings).then((fetchResponse) => {
          fetchResponse
            .json()
            .then((responseData) => {
              resolve(responseData);
            })
            .catch((e) => console.log(e));
        });
      } catch (error) {
        reject(error);
      }
    } else {
      reject("Error, no endpoint defined");
    }
  });
};
