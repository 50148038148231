import React from "react";
import { Carousel as AntdCarousel, CarouselProps } from 'antd';



export const Carousel: React.FC<CarouselProps> = (props) => {
    return (
        <AntdCarousel 
        {...props}>

        {props.children /*Carousel Content*/}

        </AntdCarousel>
    )
}
export default Carousel;