import React, { useRef } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Button as AntdButton, Col, Row, Image } from "antd";
import { Carousel, Header } from "../../Components";
import { About } from "./Views";
import sheperd1 from "../../res/img/shp2.jpg";
import produktion from "../../res/img/produktion.jpg";
import stjerne from "../../res/img/stjernerød.png";
import { Link } from "react-router-dom";
import { useWindowDimensions } from "../../Hooks";
import logo from "../../res/img/home_logo.png";

export const Home: React.FC = () => {
  const refPitch = useRef<HTMLDivElement>(null);
  const { height, width } = useWindowDimensions();

  return (
    <div>
      <Header current={"home"} />
      <Carousel autoplaySpeed={15000} autoplay effect="fade">
        {/*}
        <div>
          <div
            style={{
              fontFamily: "Poppins",
              height: "500px",
              color: "#fff",
              zIndex: 1,
              textAlign: "center",
              background: "#555",
              paddingLeft: "15%",
            }}
          >
            <Row>
              <Col xs={24} sm={20} md={16} lg={16} xl={8}>
                <div style={{ textAlign: "left" }}>
                  <h1
                    style={
                      width < 485
                        ? {
                            fontWeight: 600,
                            paddingTop: "85px",
                            paddingRight: "20px",
                            lineHeight: "50px",
                            fontSize: "28px",
                            color: "#fff",
                          }
                        : {
                            fontWeight: 600,
                            paddingTop: "85px",
                            lineHeight: "70px",
                            fontSize: "58px",
                            color: "#fff",
                          }
                    }
                  >
                    <b style={{ color: "red" }}>50</b> års jubilæum
                  </h1>
                  <h4 style={{ color: "#fff", textAlign: "left" }}>
                    <span>
                      1972-2022
                      <div style={{ display: "flex" }}>
                        <img
                          src={stjerne}
                          style={{ width: 10, height: 10, marginTop: 5 }}
                        ></img>{" "}
                        <img
                          src={stjerne}
                          style={{ width: 10, height: 10, marginTop: 5 }}
                        ></img>{" "}
                        <img
                          src={stjerne}
                          style={{ width: 10, height: 10, marginTop: 5 }}
                        ></img>
                      </div>
                    </span>
                    <br></br>
                    <div style={{ marginTop: -15 }}>
                      Dankæden har siden 1972 været hundens trofaste ven. <br />
                      Bestil dit hundehalsbånd i dag.
                    </div>
                  </h4>

                  <AntdButton
                    type="primary"
                    style={{
                      width: "180px",
                      marginTop: "20px",
                      backgroundColor: "rgba(255,0,0,0.15)",
                    }}
                  >
                    <Link to="/bestil">
                      <div style={{ display: "flex" }}>
                        <img
                          src={stjerne}
                          style={{ width: 10, height: 10, margin: 5.5 }}
                        ></img>
                        Bestil Dankæden
                      </div>
                    </Link>
                  </AntdButton>
                  <AntdButton
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      refPitch.current
                        ? refPitch.current.scrollIntoView({
                            behavior: "smooth",
                          })
                        : undefined;
                    }}
                    type="primary"
                    style={
                      width > 483
                        ? {
                            marginLeft: "10px",
                            marginTop: "0px",
                            width: "180px",
                            backgroundColor: "rgba(255,0,0,0.15)",
                          }
                        : {
                            marginLeft: "0px",
                            width: "180px",
                            backgroundColor: "rgba(255,0,0,0.15)",
                            marginTop: "10px",
                          }
                    }
                  >
                    <div style={{ display: "flex" }}>
                      <img
                        src={stjerne}
                        style={{ width: 10, height: 10, margin: 5.5 }}
                      ></img>
                      Om Dankæden
                    </div>
                  </AntdButton>
                </div>
              </Col>
              <Col xs={0} sm={4} md={8} lg={8} xl={16}>
                <div style={{ textAlign: "center" }}>
                  <img className={"mask-image"} src={sheperd1}></img>
                </div>
              </Col>
            </Row>
          </div>
        </div>
                  */}

        {
          <div>
            <div
              style={{
                fontFamily: "Poppins",
                height: "500px",
                color: "#fff",
                zIndex: 1,
                textAlign: "center",
                background: "#333",
                paddingLeft: "5%",
              }}
            >
              <Row>
                <Col xs={24} sm={20} md={16} lg={16} xl={8}>
                  <div style={{ textAlign: "left" }}>
                    <h1
                      style={
                        width < 485
                          ? {
                              fontWeight: 600,
                              paddingTop: "85px",
                              paddingRight: "20px",
                              lineHeight: "50px",
                              fontSize: "28px",
                              color: "#fff",
                            }
                          : {
                              fontWeight: 600,
                              paddingTop: "85px",
                              lineHeight: "70px",
                              fontSize: "46px",
                              color: "#fff",
                            }
                      }
                    >
                      Dansk produceret hundehalsbånd
                    </h1>
                    <h4 style={{ color: "#fff" }}>
                      Den 3-stjernede Dankæde er fremstillet i syrefast rustfrit
                      stål af bedste kvalitet.
                    </h4>

                    <AntdButton
                      type="primary"
                      style={{
                        width: "180px",
                        marginTop: "20px",
                        backgroundColor: "rgba(255,0,0,0.15)",
                      }}
                    >
                      <Link to="/bestil">
                        <div style={{ display: "flex" }}>
                          <img
                            src={stjerne}
                            style={{ width: 10, height: 10, margin: 5.5 }}
                          ></img>
                          Bestil Dankæden
                        </div>
                      </Link>
                    </AntdButton>
                    <AntdButton
                      onClick={() => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        refPitch.current
                          ? refPitch.current.scrollIntoView({
                              behavior: "smooth",
                            })
                          : undefined;
                      }}
                      type="primary"
                      style={
                        width > 483
                          ? {
                              marginLeft: "10px",
                              marginTop: "0px",
                              width: "180px",
                              backgroundColor: "rgba(255,0,0,0.15)",
                            }
                          : {
                              marginLeft: "0px",
                              width: "180px",
                              backgroundColor: "rgba(255,0,0,0.15)",
                              marginTop: "10px",
                            }
                      }
                    >
                      <div style={{ display: "flex" }}>
                        <img
                          src={stjerne}
                          style={{ width: 10, height: 10, margin: 5.5 }}
                        ></img>
                        Om Dankæden
                      </div>
                    </AntdButton>
                  </div>
                </Col>
                <Col xs={0} sm={4} md={8} lg={8} xl={16}>
                  <div style={{ textAlign: "left" }}>
                    <img className={"mask-image"} src={sheperd1}></img>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        }
        {/*/ SIDE 2 */}
        <div>
          <div
            style={{
              fontFamily: "Poppins",
              height: "500px",
              color: "#fff",
              zIndex: 1,
              textAlign: "center",
              background: "#333",
              paddingLeft: "10%",
            }}
          >
            <Row>
              <Col span={13}>
                <div style={{ textAlign: "left" }}>
                  <h1
                    style={{
                      fontWeight: 600,
                      paddingTop: "120px",
                      lineHeight: "70px",
                      fontSize: "46px",
                      color: "#fff",
                    }}
                  >
                    Forhandlere søges
                  </h1>
                  <h4 style={{ color: "#fff" }}>
                    Vi søger enkelte forhandlere, af den trestjernede dankæde.
                  </h4>
                  <AntdButton
                    type="primary"
                    style={{
                      width: "170px",
                      marginTop: "20px",
                      backgroundColor: "rgba(255,0,0,0.15)",
                    }}
                  >
                    <Link to="/forhandler">
                      <div style={{ display: "flex" }}>
                        <img
                          src={stjerne}
                          style={{ width: 10, height: 10, margin: 5.5 }}
                        ></img>{" "}
                        Bliv forhandler
                      </div>
                    </Link>
                  </AntdButton>
                </div>
              </Col>
              <Col span={11}>
                <div style={{ textAlign: "left" }}>
                  <img className={"mask-image"} src={produktion}></img>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Carousel>
      <div ref={refPitch}>
        <About></About>
      </div>

      {/*}
      <About headerText={"Hvem er vi?"} contentText={""}></About>
      <Contact></Contact>
      */}
    </div>
  );
};

export default Home;
