import React from "react";
import {Row, Col, Typography} from 'antd';
const Text = Typography.Text;

export const Credits: React.FC = () => {
  return (
    <Row
      style={{
        backgroundColor: "#333",
        fontFamily: "Poppins",
        fontStyle: "italic",
      }}
    >
      <Col span={10}></Col>
      <Col style={{ textAlign: "center" }} span={4}>
        <Text style={{ color: "#999", fontSize: "10px" }}>
          Created and maintained by{" "}
          <a style={{ color: "#fff" }} href="https://mathiasbischoff.com">
            Mathias Bischoff
          </a>
        </Text>
      </Col>
      <Col span={10}></Col>
    </Row>
  );
};
